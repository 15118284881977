import { Button, IconButton, Typography } from "@mui/material";
import * as React from "react";
import ReactToPrint from "react-to-print";
import { getQRCode } from "../services/QrCodeService";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  ICustomer,
  IPayment,
  IProduct,
  ISale,
  TPaymentMethod,
} from "../common/interfaces";
import { sha1 } from "crypto-hash";
import moment from "moment";
import * as qz from "qz-tray";
import { CommonService } from "../services/CommonService";

interface ComponentToPrintProps {
  qrValue: string;
  hash: string;
  saleInformation: Partial<ISale>;
  ticketInformation: {
    customer?: ICustomer;
    payment?: TPaymentMethod;
    sale?: ISale;
    products?: IProduct[];
    cashReceived?: number;
    authCode?: string;
  };
}
const arr: string[] = [];
function div( str: string, len: number ) {
  if( str.length < len )
      return arr.push(str);
  else
      arr.push(str.substring(0,len))

  div( str.substring(len), len );
}
class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  mapPaymentsMethods = {
    cash: "Efectivo",
    credit_card: "Tarjeta de Crédito",
    debit_card: "Tarjeta de Débito",
    gift_card: "Tarjeta de Regalo",
    vales: "Vales de despensa",
    other: "Otros",
    usd: 'Dólares',
    transfer: "Transferencia",
    credit: "Crédito",
  };

  render() {
    const { qrValue, hash, saleInformation, ticketInformation } = this.props;

    return (
      <div className="component-to-print">
        <img
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}/img/logo1.png`}
          alt="logo"
          style={{ width: "200px" }}
        />
        <Typography style={{ marginBottom: "0px" }}>
          LA BIFERIA CARNES SELECTAS
        </Typography>
        <Typography style={{ fontSize: "30px" }}>
          {/* ORDEN {ticketInformation.sale?.id} */}
        </Typography>
        <Typography style={{ fontSize: "15PX", overflow: "auto" }}>
          <span className="pull-left">FOLIO #{ticketInformation.sale?.id}</span>
          <span className="pull-right">CAJA#1</span>
        </Typography>
        <Typography
          style={{ fontSize: "13px", overflow: "auto", textAlign: "left" }}
        >
          CLIENTE: {ticketInformation.customer?.nombre}
          <br />
          DOMICILIO: {ticketInformation.customer?.direccion}
        </Typography>
        <table
          className="table table-bordered"
          style={{ width: "100%", fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "20%", textAlign: "left" }}>CANT</th>
              <th style={{ width: "60%", textAlign: "center" }}>PRODUCTO</th>
              <th style={{ width: "20%", textAlign: "right" }}>IMPORTE</th>
            </tr>
          </thead>
          <tbody>
            {ticketInformation.products?.map((val, i) => (
              <tr key={"row" + i}>
                <td className="text-right">{(Number(val.quantity) ?? 0).toFixed(2)}</td>
                <td className="text-left">
                  {val.name.substring(0, 20)}<br />
                  {/* {ticketInformation.sale?.detalles.map((value) => {
                      div(value.options ?? '', 20)
                    return (
                    <>
                    <br />{ arr.join('')  }<br />
                    </>
                    )
                  })} */}
                  {/* {val.assembled_products ? val.assembled_products.map((product: IProduct) => {
                            return (<div key={product.id}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {product.name.substring(0,20)} <br /></div>)
                          }) : ''} */}
                </td>
                <td className="text-right">
                  {(val.price_1 * (Number(val.quantity) ?? 0)).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table
          style={{
            width: "200px",
            fontSize: "12px",
            marginTop: "20px",
            marginLeft: "180px",
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>Subtotal</td>
              <td style={{ width: "60%" }} className="text-right">
                ${ticketInformation.sale?.subtotal} MXN
              </td>
            </tr>
            <tr>
              <td>Impuestos</td>
              <td className="text-right">
                $
                {(
                  parseFloat(ticketInformation.sale?.iva ?? "0") +
                  parseFloat(ticketInformation.sale?.ieps ?? "0")
                ).toFixed(2)}{" "}
                MXN
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td className="text-right">
                ${ticketInformation.sale?.total} MXN
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table
          style={{ width: "150px", fontSize: "12px", marginLeft: "230px" }}
        >
          <tbody>
            {ticketInformation.sale &&
            ticketInformation.sale.payments &&
            ticketInformation.sale.payments.length > 0 ? (
              <>
                {ticketInformation.sale.payments.map((val: IPayment, i) => (
                  <>
                    <tr key={"row" + i}>
                      <td style={{ width: "40%" }}>
                        {this.mapPaymentsMethods[val.payment_method]}
                      </td>
                      <td className="text-right">
                        ${" "}
                        {
                          val.payment_method === "cash" ||
                          val.payment_method === "vales"
                          ? val.cash_received
                          : 
                          val.payment_method === 'usd' ? 
                          val.usd :
                          val.amount}{" "}
                        {val.payment_method === 'usd' ? 'USD' : 'MXN'}
                      </td>
                    </tr>
                    {val.payment_method === "cash" || val.payment_method === 'usd' ? (
                      <tr>
                        <td>CAMBIO</td>
                        <td className="text-right">
                          ${((val.cash_received ?? 0) - val.amount).toFixed(2)}{" "}
                          MXN
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
        <hr />
        <Typography style={{ fontWeight: "bold" }}>
          ¡¡¡Gracias por su compra!!!
        </Typography>
        <Typography style={{ fontSize: "10px" }}>
          Fecha: {moment().format("DD/MM/YYYY")} HORA:{" "}
          {moment().format("HH:mm")}
        </Typography>
        <Typography style={{ fontSize: "10px" }}>
          LE ATENDIO: CAJERA 1
        </Typography>
        <Typography style={{ fontSize: "10px" }}>
          Ordene al: <WhatsAppIcon style={{ fontSize: "15px" }} />
          8994 26 58 40
        </Typography>
        <Typography style={{ fontSize: "10px" }}>
          Para facturar escanea el código QR. o ingrese a: <br />
          <span style={{ textTransform: "lowercase" }}>
            https://facturacion.labiferia.com.mx/
          </span>
          <br />
          con el codigo: <br />
          {hash}
        </Typography>
        <br />
        <img
          className="img-fluid"
          src={getQRCode(qrValue)}
          style={{ width: "150px" }}
        />
      </div>
    );
  }
}

interface salesReceiptProps {
  trigger?: () => void;
  saleInformation: Partial<ISale>;
  ticketInformation: {
    customer?: ICustomer;
    payment?: TPaymentMethod;
    sale?: ISale;
    products?: IProduct[];
    cashReceived?: number;
  };
}

export const SalesReceipt = (props: salesReceiptProps) => {
  const componentRef = React.useRef(null);
  const { saleInformation, ticketInformation } = props;
  const [saleHash, setSaleHash] = React.useState<string>("");
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handleOnClick = () =>{
    CommonService.printTicketSale(ticketInformation.sale?.id ?? 0).then(() => {
      console.log('Reimprimiendo Ticket')
    })
  }
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div style={{ display: "none", justifyContent: "right" }}>
        <Button variant="contained">
          <LocalPrintshopIcon />
        </Button>
      </div>
    );
  }, []);

  React.useEffect(() => {
    (async () => {
      setSaleHash(await sha1(saleInformation.id?.toString() || ""));
    })();
  }, []);
  return (
    <div
      style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}
    >
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        // onAfterPrint={handleAfterPrint}
        // onBeforeGetContent={handleOnBeforeGetContent}
        // onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button variant="contained" onClick={() => handleOnClick()}>
            <LocalPrintshopIcon />
          </Button>
        </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        
        <ComponentToPrint
          qrValue={"https://facturacion.labiferia.com.mx/?sale=" + saleHash}
          hash={saleHash}
          saleInformation={saleInformation}
          ticketInformation={ticketInformation}
          ref={componentRef}
        />
      </div>
    </div>
  );
};
