/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import "@sweetalert2/theme-dark/dark.css";
import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "../auth/useLocalStorage";
import {
  ComponentModal,
  ICustomer,
  IProduct,
} from "../common/interfaces";
import { BottomInfo } from "../components/bottomInfo";
import { CartView } from "../components/cartView";
import { ConfirmationDialogRaw } from "../components/confirmationDialogRaw";
import { ExchangeRate } from "../components/exchangeRateBox";
import { HeaderCartView } from "../components/headerCartView";
import { MainModal } from "../components/mainModal";
import { MainMenu } from "../components/menu";
import { ProductSideBar } from "../components/productSideBar";
import { TotalsRow } from "../components/totalsRow";
import { useModalStore } from "../context/modalStore";
import { usePosStore } from "../context/posStore";
import { usePusher } from "../context/pusherContext";
import { CashBoxService } from "../services/CashBoxService";
import { CommonService } from "../services/CommonService";
import { CustomerService } from "../services/CustomerService";
import { productsService } from "../services/productsService";
import { useNotificationStore } from "../context/notificationStore";
import NotificationSound from "../components/NotificationSound";


interface HomeProps {
  openCustomer: boolean;
  handleCloseCustomer: () => void;
}
const Home: React.FC<HomeProps> = () => {
  // const productsInCart = usePosStore((state) => state.products)
  // console.log({productsInCart})
  const modalManager = useModalStore();
  const order = usePosStore((state) => state.order);
  const customerStored = usePosStore((state) => state.customer);
  const productsInOrder = usePosStore((state) => state.products);
  const setExchange = usePosStore((state) => state.setExchangeRate);
  const [user, setUser] = useLocalStorage("user", null);
  const [transfer, setTransfer] = useLocalStorage("transfer", null);
  const [search] = React.useState("");
  const [, setProducts] = React.useState([] as IProduct[]);
  const [orderProducts, ] = React.useState([] as IProduct[]);
  
  const [open, setOpen] = React.useState(false);
  const [amountCash, setAmountCash] = React.useState("");
  const [exchangeRate, setExchangeRate] = React.useState(0);

  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
  

  const [, setOpenModal] = useState(false);
  const [, setModalTitle] = useState("");
  const [, setModalOption] = useState("");

  const [, setCustomers] = useState<ICustomer[]>([]);

  const [searchCustomer] = useState("");
  // const [openNotification, setOpenNotification] = useState(false);
  const pusher = usePusher();
  // const [openCustomer, setOpenCustomer] = React.useState(false);

  // const handleCloseCustomer = () => setOpenCustomer(false);
  const debounceLoadData = useCallback(debounce(getProducts, 1000), []);
  function getProducts(filter: string) {
    productsService.getProducts(filter).then((res) => {
      // console.log(res);
      setProducts(res.data);
    });
  }
  const notification = useNotificationStore()
  useEffect(() => {
    if (!user.cashOpen) {
      setOpen(true);
    }
    debounceLoadData(search);

    CommonService.getExchangeRate().then((res) => {
      if (res.success) {
        setExchangeRate(res.object.rate);
        setExchange(Number(res.object.rate ?? 1));
      }
    });

    const channel = pusher.subscribe("general-channel");
    channel.bind(
      "general-alert",
      (event: {
        object: {
          bill?: any;
          withdraw?: any;
          to?: any;
          status: string;
          id: string;
          message: string;
          action?: string;
          order?: any;
        };
        success: boolean;
      }) => {
        console.log("Recibiendo notificación", { event });
        console.log(event.object.to === user.id)
        if (event.object.to === user.id) {

          if(event.object.action == 'tienda'){
            setNotificationMessage(event.object.message);
          }else{
            CommonService.toast(event.object.message, "success");
          }

          console.log("order event", event.object.order);
          if (
            event.object.status === "authorized" &&
            event.object.action &&
            event.object.action !== "none"
          ) {
            if (event.object.order) {
              if (event.object?.order?.sale_type === "Order") {
                setNotificationMessage(event.object.message);

                CommonService.printTicketOrder(event.object.order.id)
                  .then(() => {
                    console.log("printing Comanda");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              CommonService.printTicketSale(event.object.order.id)
                .then(() => {
                  console.log("printing");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if(event.object.bill){
              CommonService.generatePrintCommand(event.object.bill, 'bill').then(e => console.log(e))
            }

            if(event.object.withdraw){
              // CommonService.generatePrintCommand(event.object.bill, 'bill').then(e => console.log(e))
              CommonService.openCashDrawer().then((res) => {
                console.log("ABRIENDO CAJON");
              });

              CommonService.printTicketWithdraw(event.object.withdraw).then((res) => {
                console.log('Withdraw Ticket')
              });
            }

          }else{
            notification.getNotifications(user.id)
          }
        }
      }
    );
  }, []);

  const handleCarritoButton = () => {
    console.log({ orderProducts });
    if (orderProducts.length > 0) {
      setOpenModal(false);
      setOpenToOrder(true);
    } else {
      console.log("here");
      setModalTitle("Utilidades");
      setModalOption("utilidades");
      setDefaultOpen("orders");
      setOpenModal(true);
    }
  };
  useEffect(() => {
    const evtListener = (e: any) => {
      if (e.key === "F2") {
        if (productsInOrder.length === 0) {
          CommonService.toast("No hay productos en la orden", "error");
          return;
        }
        modalManager.openModal(ComponentModal.Finish, {
          order,
          products: productsInOrder,
          customer: customerStored,
          type: "order",
        });
      }
      if (e.key === "F8") {
        handleCarritoButton();
      }
    };
    window.addEventListener("keydown", evtListener);
    return () => {
      window.removeEventListener("keydown", evtListener);
    };
  }, [order.total, customerStored]);

  useEffect(() => {
    if (amountCash) {
      setOpen(false);
      CashBoxService.openBox({
        cash_number: 1,
        open_amount: parseFloat(amountCash),
        usd_open_amount: 0,
        user_id: user.id,
        warehouse_id: user.warehouse_id,
      }).then((res) => {
        if (res.success) {
          setUser({
            ...user,
            cashOpen: true,
            cash: amountCash,
            cbox: res.object,
          });
          if (transfer) {
            const fromCashbox = transfer.cashBoxId;
            const toCashBox = res?.object?.id;
            console.log({ fromCashbox, toCashBox });
            CommonService.transferPendingOrders(
              fromCashbox,
              toCashBox as unknown as string
            ).then(() => {
              console.log("Transfiriendo Pedidos.");
              setTransfer(null);
            });
          }
        } else {
          setUser({ ...user, cashOpen: true, cash: amountCash, cbox: {} });
        }
      });
    }
  }, [amountCash]);

  const [, setDefaultOpen] = useState("");
  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setAmountCash(newValue);
      return;
    }
    localStorage.clear();
    window.location.reload();
  };
  const getCustomers = (name: string) => {
    CustomerService.getCustomers(name).then((res) => {
      setCustomers(res);
    });
  };

  const debounceLoadDataCustomer = useCallback(
    debounce(getCustomers, 1000),
    []
  );
  useEffect(() => {
    debounceLoadDataCustomer(searchCustomer);
  }, [searchCustomer]);

  const [, setOpenToOrder] = useState(false);
  return (
    <Container maxWidth={"xl"}>
      {notificationMessage && (
      <NotificationSound
        message={notificationMessage}
        onClose={() => setNotificationMessage(null)}
      />
      )}

      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        value={amountCash}
      />
      <MainModal />
      <Grid container spacing={3} style={{ marginTop: "30px" }}>
        <Grid item xs={2}>
          <MainMenu />
        </Grid>
        <Grid item xs={10} bgcolor={"#222"} style={{ borderRadius: "15px" }}>
          <Grid container spacing={3} style={{ padding: "10px" }}>
            <Grid item xs={8}>
              <HeaderCartView />
              <CartView />
              <TotalsRow />
            </Grid>
            <Grid item xs={4}>
              <ExchangeRate exchangeRate={exchangeRate} />
              <ProductSideBar />
              <BottomInfo />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
