
import React, { useEffect, useState } from "react";

interface NotificationProps {
  message: string;
  onClose: () => void;
}

const NotificationSound: React.FC<NotificationProps> = ({ message, onClose }) => {
  const [audio] = useState(() => new Audio("/sound/alerta.mp3"));

  useEffect(() => {
    audio.loop = true;
    audio
      .play()
      .catch((err) => console.error("Error playing alert sound:", err));
    
    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset sound
    };
  }, [audio]);

  return (
    <div
      style={{
        position: "fixed",
        top: "10px",
        right: "10px",
        background: "#333",
        color: "#fff",
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
      }}
    >
      <h2>{message}</h2>
      <button
        onClick={onClose}
        style={{
          background: "red",
          color: "#fff",
          border: "none",
          padding: "5px 10px",
          borderRadius: "3px",
          cursor: "pointer",
          marginTop: "10px",
        }}
      >
        Cerrar
      </button>
    </div>
  );
};

export default NotificationSound;
