import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { usePosStore } from "../context/posStore";
import { ComponentModal, TitleComponents } from "../common/interfaces";
import { Customer } from "./modal/customer";
import { AddCustomer } from "./modal/addCustomer";
import { useModalStore } from "../context/modalStore";
import { Cancel } from './modal/cancel';
import { CancelItem } from "./modal/cancelItem";
import { Finish } from "./modal/finish";
import { OrderOptions } from "./modal/orderOptions";
import { Utilidades } from "./modal/utilidades";
import { OrderNotes } from "./modal/orderNotes";
import { ScheduleOrders } from "./modal/scheduleOrders";
import { Notifications } from "./modal/notifications";
const style = {
  position: "relative",
  maxHeight: "700px",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#333",
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

// interface MainModalProps {
//   componentName: ComponentModal;
// }

export const MainModal: React.FC = () => {
  const [modalKey, setModalKey] = useState(0);
  const modalManager = useModalStore();
  useEffect(() => {
    console.log(modalManager.component)
    if(modalManager.component !== "") modalManager.setTitle(TitleComponents[modalManager.component]);
  }, [modalManager.component])

  useEffect(() => {
    console.log('reload')
  }, [])
  return (
    <Modal
      key={modalKey}
      open={modalManager.open}
      onClose={(e,reason) => {
        if(reason === "backdropClick") return;
        modalManager.closeModal()
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: "95%" }}>
        <h2 id="parent-modal-title">{modalManager.title}</h2>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={modalManager.closeModal}
          style={{ marginTop: "-55px", float: "right" }}
        >
          <CloseIcon />
        </IconButton>
        {modalManager.component === ComponentModal.Customer && <Customer />}
        {modalManager.component === ComponentModal.AddCustomer && <AddCustomer />}
        {modalManager.component === ComponentModal.Cancel && <Cancel />}
        {modalManager.component === ComponentModal.CancelItem && <CancelItem />}
        {modalManager.component === ComponentModal.Finish && <Finish />}
        {modalManager.component === ComponentModal.OrderOptions && <OrderOptions />}
        {modalManager.component === ComponentModal.Utilidades && <Utilidades />}
        {modalManager.component === ComponentModal.LogOut && <Utilidades />}
        {modalManager.component === ComponentModal.OrderNotes && <OrderNotes />}
        {modalManager.component === ComponentModal.ScheduleOrders && <ScheduleOrders />}
        {modalManager.component === ComponentModal.Notification && <Notifications />}
      </Box>
    </Modal>
  );
};
